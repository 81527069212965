import { render, staticRenderFns } from "./BasicSwitch.vue?vue&type=template&id=fdb28450&scoped=true&"
import script from "./BasicSwitch.vue?vue&type=script&lang=js&"
export * from "./BasicSwitch.vue?vue&type=script&lang=js&"
import style0 from "./BasicSwitch.vue?vue&type=style&index=0&id=fdb28450&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdb28450",
  null
  
)

export default component.exports