<template>
  <b-col cols="12">
    <b-form-group>
      <b-row class="">
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            v-if="explain.length > 0"
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-for="(item, index) in select"
              :key="index"
              v-model="selected"
              :name="label"
              :value="item.value"
              class="custom-control-primary mr-2 mb-1"
              @input="send"
            >
              {{ item.labelName }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BFormRadio, VBPopover, BPopover, BCol, BRow, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormRadio,
    BPopover,
    BCol,
    BRow,
    BFormGroup,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    select: {
      type: Array,
      default: () => [],
    },
    explain: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {},
      titleList: [],
      choiceData: {},
    }
  },
  watch: {
    value: {
      handler() {
        if (Object.keys(this.value).length !== 0) {
          this.selected = this.value.value
        }
      },
      immediate: true,
      deep: true,
    },
    defaultValue: {
      handler() {
        this.setData()
      },
      immediate: true,
      deep: true,
    },
    selected: {
      handler() {
        this.send()
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // window.console.log('🐶✨️ radio', this.selected)
    // this.setData()
  },
  methods: {
    send() {
      this.$emit('childData', this.select.find(v => v.value === this.selected))
    },
    setData() {
      /* eslint-disable prefer-destructuring */
      if (this.defaultValue.length !== 0) {
        this.selected = this.defaultValue[0].value
        // this.send()
      }
      /* eslint-enable */
    },
  },
}
</script>

<style scoped>
</style>
