<template>
  <div>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.indexOf(item.type) !== -1"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :select="item.type ==='checkboxSearch' ? item.choice: []"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="inputForm[item.key]"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :description="typeof item.description !== 'undefined'? item.description: ''"
            :select="item.type ==='checkbox' ? item.choice: []"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
        <b-row>
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="search"
            >
              検索する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>

      </b-form>
      <!-- <SuccessBasic
        ref="sbchild"
        to-page="student-profile"
      /> -->
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
import menulist from '@/components/conf/JPCAREER様_就労PF_求人企業_求職者検索条件.json'
import choice from '@/components/conf/JPCAREER様_学習PF_選択肢.json'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      menulist,
      useValueDicList: ['checkboxSearch', 'selectSingle'],
      choice,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'company-basicsetting' })
        this.status = 1
      })
    },
    search() {
    },
  },
}
</script>
