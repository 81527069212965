<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <b-form-input
            v-model="birthday"
            placeholder="YYYY-MM-DD"
            @click="showModal"
          />
          <small>{{ description }}</small>
        </b-col>
      </b-row>
    </b-form-group>
    <b-modal
      v-model="isShowModal"
      size="sm"
      title="生年月日の登録"
      centered
      ok-only
      ok-variant="outline-secondary"
      ok-title="登録する"
      no-close-on-backdrop
      @ok="send"
    >
      <b-row class="mt-2">
        <b-col cols="3">
          <div class="text-right">
            年(西暦)
          </div>
        </b-col>
        <b-col>
          <b-form-input
            v-model="year"
            placeholder="1990"
            type="number"
            class="mb-1"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <div class="text-right">
            月
          </div>
        </b-col>
        <b-col>
          <b-form-input
            v-model="month"
            placeholder="8"
            type="number"
            class="mb-1"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <div class="text-right">
            日
          </div>
        </b-col>
        <b-col>
          <b-form-input
            v-model="day"
            placeholder="3"
            type="number"
            class="mb-1"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-col>
</template>

<script>
import {
  BCol, BFormGroup, BRow, VBPopover, BPopover, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    BFormGroup,
    BRow,
    BPopover,
    BFormInput,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
      isShowModal: null,
      year: '',
      month: '',
      day: '',
    }
  },
  computed: {
    birthday() {
      /* eslint-disable */
      if (this.year === '' | this.month === '' | this.day === '') return 'YYYY-MM-DD'
      return this.year + '-' + this.getDoubleDigit(this.month) + '-' + this.getDoubleDigit(this.day)
      /* eslint-enable */
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value !== '') {
          const strList = this.value.split('-')
          /* eslint-disable */
          this.year = strList[0]
          this.month = Number(strList[1])
          this.day = Number(strList[2])
          /* eslint-enable */
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    send() {
      this.$emit('childData', this.birthday)
      // window.console.log('memo', this.birthday)
    },
    showModal() {
      this.isShowModal = true
    },
    getDoubleDigit(val) {
      const target = `0${val}`
      return target.substr(target.length - 2, 2)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.explainText {
  padding: 3% 0;
}
.collapse_ {
  margin: -1% -1%;
}
.selectInline {
  display: flex;
}
</style>
