<template>
  <div>
    <div class="ml-1 mb-2">
      <div>
        {{ label }}
      </div>
      <b-img
        v-if="uploadFile !== ''"
        thumbnail
        fluid
        :src="uploadFile"
      />
      <b-form-file
        v-model="file"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        @input="upload"
      />
      <!-- Selected file: <strong>{{ file ? file.name : '' }}</strong> -->
    </div>
  </div>
</template>

<script>
import { BFormFile, BImg } from 'bootstrap-vue'
import firebase from 'firebase/app'

export default {
  components: {
    BFormFile,
    BImg,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      file: null,
      uploadFile: null,
    }
  },
  watch: {
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value !== '') {
          this.uploadFile = this.value
        }
      },
      immediate: true,
    },
  },
  methods: {
    async upload() {
      /* eslint-disable */
      try {
        // window.console.log('🐶', this.file.name)
        const storage = firebase.storage()
        const storageRef = await storage.ref('articles/' + this.file.name)
        // window.console.log('🚀', storageRef)
        await storageRef.put(this.file)
        this.uploadFile = await storageRef.getDownloadURL()
        // window.console.log('🍎', this.uploadFile)
        this.$emit('childData', this.uploadFile)
      } catch (error) {
        window.console.log('error', error)
      }
      /* eslint-enable */
    },
  },
}
</script>
