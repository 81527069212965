<template>
  <b-col cols="12">
    <b-form-group
      :label="label"
      label-cols-md="4"
    >
      <div class="">
        <small>{{ description }}</small>
      </div>
      <b-form-tags
        v-model="selected"
        separator=" ,"
        placeholder=""
        size="lg"
        class=""
      />
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BCol, BFormTags, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BFormTags,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  watch: {
    value: {
      handler() {
        // window.console.log('🚀 ', this.value, Object.keys(this.value).length)
        if (this.value.length !== 0) {
          this.selected = this.value
          return
        }
        /* eslint-disable prefer-destructuring */
        if (this.defaultValue.length !== 0) {
          this.selected = this.defaultValue[0]
        }
        this.send()
        /* eslint-enable */
        // window.console.log('🌺', this.selected, this.defaultValue)
      },
      immediate: true,
    },
    selected: {
      handler() {
        this.send()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    send() {
      this.$emit('childData', this.selected)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
