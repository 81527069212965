<template>
  <div>
    <h2>求職者検索</h2>
    <SearchEdit />
    <SearchTable />
  </div>
</template>

<script>
import SearchEdit from '@/components/company/seachJobSeeker/SearchEdit.vue'
import SearchTable from '@/components/company/seachJobSeeker/SearchTable.vue'

export default {
  components: {
    SearchEdit,
    SearchTable,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
