<template>
  <b-col cols="12">
    <b-form-group
      :label="label"
      label-cols-md="4"
    >
      <!-- <b-form-checkbox-group
        v-model="selected"
        :options="options"
        switches
        class="switchSpace"
        @input="send"
      /> -->
      <b-form-checkbox
        v-model="selected"
        name="check-button"
        switch
        inline
        class="switchSpace"
        @input="send"
      >
        {{ msg }}
      </b-form-checkbox>
    </b-form-group>
  </b-col>
</template>

<script>
import { BFormCheckbox, BFormGroup, BCol } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BCol,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    choice: {
      type: Array,
      default: () => [],
    },
    // value: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  data() {
    return {
      selected: false,
      msg: '',
    }
  },
  watch: {
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (typeof this.value !== 'undefined') {
          if (this.choice.length === 0) {
            this.value === 'on' ? this.selected = true : this.selected = false
            return
          }
          const [on] = this.choice
          this.value === on ? this.selected = true : this.selected = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    send() {
      window.console.log('🐶', this.selected)
      if (this.choice.length === 0) {
        const input = this.selected === true ? 'on' : 'off'
        this.$emit('childData', input)
        if (this.selected) {
          this.msg = 'ON'
        } else {
          this.msg = 'OFF'
        }
        return
      }
      const [on, off] = this.choice
      const input = this.selected === true ? on : off
      this.$emit('childData', input)
      if (this.selected) {
        this.msg = on
      } else {
        this.msg = off
      }
    },
  },
}
</script>
<style scoped>
.switchSpace {
  margin: 2% 0 0 0;
}
</style>
