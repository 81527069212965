<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
        </b-col>
        <b-col>
          <small>郵便番号</small>
          <div class="zip-space">
            <b-form-input
              v-model="zipcode"
              placeholder="1020012"
              type="number"
              :readonly="disabled"
              class=""
              @input="send"
            />
          </div>
          <div class="mb-1">
            <small>※半角英数でご入力ください。</small>
          </div>
          <small>都道府県</small>
          <b-form-input
            v-model="prefecture"
            placeholder="東京都"
            class="mb-1"
            @input="send"
          />
          <small>住所1</small>
          <b-form-input
            v-model="address1"
            placeholder="港区海岸1-2-3"
            class="mb-1"
            @input="send"
          />
          <small>住所2</small>
          <b-form-input
            v-model="address2"
            placeholder="転コレマンション 1234号"
            class="mb-1"
            @input="send"
          />
          <div class="">
            <small>{{ description }}</small>
          </div>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
  },
  directives: {
    Ripple,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    valueDic: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
      zipcode: '',
      prefecture: '',
      address1: '',
      address2: '',
    }
  },
  watch: {
    valueDic: {
      handler() {
        if (this.valueDic) {
          this.zipcode = typeof this.valueDic.zipcode !== 'undefined' ? this.valueDic.zipcode : ''
          this.prefecture = typeof this.valueDic.prefecture !== 'undefined' ? this.valueDic.prefecture : ''
          this.address1 = typeof this.valueDic.address1 !== 'undefined' ? this.valueDic.address1 : ''
          this.address2 = typeof this.valueDic.address2 !== 'undefined' ? this.valueDic.address2 : ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    send() {
      const data = {
        zipcode: this.zipcode,
        prefecture: this.prefecture,
        address1: this.address1,
        address2: this.address2,
      }
      this.$emit('childData', data)
    },
  },
}
</script>

<style scoped>
.explainText {
  padding: 2% 0;
}
.collapse_ {
  margin: -1% -1%;
}
.zip-space {
  width: 50%;
}
</style>
